<template>
  <div id="app">
    <nav>
        <h3>Netflix</h3>
    </nav>
    <div class="main-preview"></div>
    <Carousel></Carousel>
  </div>
</template>

<script>
import Carousel from './Carousel.vue';
import Vue from 'vue';
window.Bus = new Vue();

export default {
  name: 'app',
  components: {
    Carousel,
  }
}
</script>

<style>
html, body {
  background-color: #1b1b1b;
  color: white;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin: 0;
}

nav h3 {
  color: #ee0029;
}

.body-content {
    padding-left: 40px;
}
</style>
