import Vue from 'vue';
import App from './components/Netflix/App.vue';
import EasingFunctions from './easing';

window.easing = EasingFunctions;
Vue.config.productionTip = false;

new Vue({
  render: h => h(App)
}).$mount('#app')
