<template>
    <div class="banner" style="background-image: url(https://i.imgur.com/s1P5nkU.png);">
        <div class="close-button" v-on:click="closeBanner"><i class="material-icons">close</i></div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {

        };
    },

    methods: {
        closeBanner: function() {
            console.log('close banner');
            window.Bus.$emit('banner:close');
        }
    }
};
</script>

<style scoped>
.banner {
    width: 100%;
    height: 640px;
    background-color: #000;
    position: relative;
    top: -76px;
    left: 0;

    background-repeat: no-repeat;
    background-size: cover;
}

.close-button {
    color: white;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}
</style>