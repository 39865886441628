<template>
    <div>
        <div class="carousel">
            <header>{{ title }}</header>
            <div class="item-listing">
                <div v-for="item in items"
                    v-bind:key="item.id"
                    class="item"
                    v-bind:class="{ selectedItemClass: isSelected(item.id) }"
                >
                    <div class="item-overlay">
                        <div class="arrow" v-on:click="selectItem(item.id)">
                            <div class="down-a"></div>
                        </div>
                    </div>
                    <div class="item-details">
                        <b>{{ item.title }}</b><br/>
                        <b>S2:E6</b> "The Hard Reality"
                    </div>
                    <img :src="item.thumbnail" :alt="item.title">
                </div>
            </div>
            <div class="carousel-ctrl carousel-ctrl--left" v-on:click="panLeft"></div>
            <div class="carousel-ctrl carousel-ctrl--right" v-on:click="panRight"></div>
        </div>
        <Banner v-if="selectedItem"></Banner>
    </div>
</template>

<script>
// @TODO: add endpoint to data.json
// https://endpoints.uncaughtexception.wtf/b42e855f01a24a1fa06422f9544fad67/view
// res/data.json
import Banner from './Banner';

export default {
    components: {
        Banner
    },
    data: function() {
        return {
            title: 'Popular on Netflix',
            items: [
                {
                    "id": 1,
                    "thumbnail": "https://i.imgur.com/UOaTmAv.png",
                    "title": "The Flash",
                    "synopsis": "It's showdown time in Central City"
                },
                {
                    "id": 2,
                    "thumbnail": "https://i.imgur.com/Kk3Z7lz.png",
                    "title": "Elementary",
                    "synopsis": "A modern take on the cases of Sherlock Holmes, with the detective now living in New York City"
                },
                {
                    "id": 3,
                    "thumbnail": "https://i.imgur.com/lFBGeHT.png",
                    "title": "The Rain",
                    "synopsis": "The world as we know it has ended. Six years after a brutal virus carried by the rain wiped out almost all humans in Scandinavia, two Danish siblings emerge from the safety of their bunker to find all remnants of civilization gone."
                },
                {
                    "id": 4,
                    "thumbnail": "https://i.imgur.com/evosYm1.png",
                    "title": "Riverdale",
                    "synopsis": "Betty Cooper unravels her feelings for Archie, and her family secrets. ... Archie, Betty, Veronica, and Jughead band together to find the mysteries behind Jason Blossom's murder."
                },
                {
                    "id": 5,
                    "thumbnail": "https://i.imgur.com/ThZbEuW.png",
                    "title": "Stranger Things",
                    "synopsis": "When a young boy vanishes, a small town uncovers a mystery involving secret experiments, terrifying supernatural forces and one strange little girl."
                },
                {
                    "id": 6,
                    "thumbnail": "https://i.imgur.com/bNRagty.png",
                    "title": "Defenders",
                    "synopsis": "A set of fictional superhero groups with rotating membership appearing in American comic books published by Marvel Comics."
                },
                {
                    "id": 6,
                    "thumbnail": "https://i.imgur.com/Q1LbVft.png",
                    "title": "Scorpion",
                    "synopsis": "Based on the real life of Walter O'Brien, a genius with an IQ of 197. Walter is asked by Homeland Security to put together a special group of gifted individuals to troubleshoot the most difficult problems that the US & the world may encounter."
                }
            ],
            selectedItem: null
        };
    },
    methods: {
        panLeft: function(e) {
            let carousel = e.target.parentElement.querySelector('.item-listing');
            let panning = carousel.querySelector('.item').offsetWidth * 3;
            if (carousel.scrollLeft <= 0) {
                return;
            }
            carousel.scrollLeft -= panning;
        },

        panRight: function(e) {
            let carousel = e.target.parentElement.querySelector('.item-listing');
            let panning = carousel.querySelector('.item').offsetWidth * 3;
            carousel.scrollLeft += panning;
        },

        selectItem: function(itemId) {
            this.selectedItem = itemId;
        },

        isSelected: function(itemId) {
            return this.selectedItem === itemId;
        }
    },

    created() {
        window.Bus.$on('banner:close', () => {
            this.selectedItem = null;
        });
    }
};
</script>


<style scoped>

.carousel {
    color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-left: 40px;
}

header {
    margin-bottom: 12px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 700;
}

.item-listing {
    position: relative;
    top: -42px;
    min-height: 236px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: auto;
}

.item {
    background-color: rgba(0, 0, 0, 0.2);
    min-width: 290px;
    height: 163px;
    margin-right: 4px;
    cursor: pointer;
    flex-shrink: 0;
    transition: all 0.2s ease-in-out;
    transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
    transition-delay: 50ms;
    z-index: 1;
    align-self: center;
}

.item.selectedItemClass {
    border: 4px solid white;
}
.item.selectedItemClass::after {
    content: '';
    position: relative;
    bottom: -14px;
    left: 125px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 11px solid #fff;
    clear: both;
}

.item img {
    min-width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    position: relative;
    display: block;
    vertical-align: bottom;
}

.item:hover {
    min-width: 420px;
    height: 236px;
    z-index: 6;
}

.item-overlay {
    position: absolute;
    z-index: 6;
    background: none;
    min-width: 290px;
    height: 163px;
    transition: all 0.2s ease-in-out;
}

.item:hover > .item-overlay {
    min-width: 420px;
    height: 236px;
    background-image: linear-gradient(
            transparent 0%,
            rgba( 0, 0, 0, 1 ) 100% );
}

::-webkit-scrollbar {
    display: none;
}

.item-details {
    z-index: 8;
    position: absolute;
    bottom: 0px;
    padding-left: 10px;
    padding-bottom: 20px;
    font-size: 12px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.item:hover .item-details {
    opacity: 1;
    font-size: 16px;
    padding-bottom: 16px;
}

.carousel-ctrl {
    position: absolute;
    top: 24px;
    z-index: 5;

    width: 30px;
    height: 166px;
    cursor: pointer;
    background-color: rgba(0,0,0,0.5);
    transition: background-color 0.2s ease-in;
}

.carousel-ctrl:hover {
    background-color: rgba(0,0,0,0.75);
}

.carousel-ctrl--left {
    left: 0;
}

.carousel-ctrl--right {
    right: 0;
}

.arrow {
    position: absolute;
    bottom: 12px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0px;
    opacity: 0;
}

.down-a {
    box-sizing: border-box;
    height: 1vw;
    width: 1vw;
    border-style: solid;
    border-color: white;
    border-width: 0px 1px 1px 0px;
    transform: rotate(45deg);
    transition: border-width 150ms ease-in-out;
}

.item:hover .arrow {
    opacity: 1;
}

.arrow:hover .down-a {
    border-color: #ee0029;
}

</style>
